<template>
  <div id="NavBarContainer" class="navbar" :class="{ 'navbar--hidden': !showNavbar }" >
    <div class="container" style="padding:0px;">
      <div class="row justify-content-center" id="nopadding" ondragstart="return false;" ondrop="return false;">

        <div id="toast" v-on:click="mNavOpen" class="col col-1 d-block d-md-none">
          <img id="toast_btn" src="@/assets/toast_white.svg">
        </div>
        <div id="mobileNav"  v-on:click="mNavClose">

            <router-link to="/">
              <img src="@/assets/gachita.png" id="mNavLogo">
            </router-link>
            <div id="mNavCancel" v-on:click="mNavClose" class="col col-1 d-block d-md-none">
              <img src="@/assets/mNavCancel.svg" id="" alt="">
            </div>

          <div id="mNavTextContainer">

            <div class="mNavText" >
            </div>
            <div class="w-100 mNavSpacer"></div>
            <router-link class="mNavText" to="/Gachita">
                  {{ Gachita_Title }}
            </router-link>
            <div class="w-100 mNavSpacer"></div>
            <router-link class="mNavText"  to="/Contact">
                  Contact
            </router-link>

          </div>

        </div>

        <router-link to="/" class="col-auto col-md-2 LogoContainer">
            <img id="navLogo" src="@/assets/gachita.png" class="Logo"/>
        </router-link>

          <div class="navText offset-1 col-3 d-none d-md-block right-align" >
          </div>

          <router-link class="navText col-md-3 col-lg-4 d-none d-md-block"  to="/Gachita" style="text-align: right">
                {{ Gachita_Title }}
          </router-link>

          <router-link class="navText d-none d-md-block col-2 left-align"  to="/Contact">
                Contact
          </router-link>

      </div>
    </div>
  </div>

</template>

<script>
export default {
    name: 'NavBar',
    data(){
        if(true)        // eng sup after contents are updated
        return {
            Gachita_Title: "차량 관리 솔루션",
            showNavbar: true,
            lastScrollPosition: 0,
            onLandingPage: true,
        }
    },
    mounted () {
      window.addEventListener('scroll', this.onScroll);
      var navText_arr = document.getElementsByClassName("navText");
      var nav_toast_btn = document.getElementById("toast_btn");
      var navLogo = document.getElementById("navLogo");

      if(this.$router.currentRoute.path == "/"){
        this.onLandingPage = true;
        var i;
        navLogo.src = require("@/assets/gachita.png");
        nav_toast_btn.src = require("@/assets/toast_white.svg");


        for (i=0; i<navText_arr.length; i++)
          navText_arr[i].id = "navText_main"
      }
      else{
        this.onLandingPage = false;
        var i;
        navLogo.src = require("@/assets/gachita.png");
        nav_toast_btn.src = require("@/assets/toast_black.svg");
        for (i=0; i<navText_arr.length; i++)
          navText_arr[i].id = "navText_contents"
      }

    },
    beforeDestroy () {
      window.removeEventListener('scroll', this.onScroll)
    },
    methods:{
        onScroll () {
        // Get the current scroll position
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero

        var navText_arr = document.getElementsByClassName("navText");
        var navLogo = document.getElementById("navLogo");

        var nav_toast_btn = document.getElementById("toast_btn");
        // console.log(currentScrollPosition);
        if (currentScrollPosition < 0) {
          return;
        }
        if(this.lastScrollPosition == currentScrollPosition)
          return;
        // Here we determine whether we need to show or hide the navbar
        if((currentScrollPosition <= this.lastScrollPosition) || (currentScrollPosition <= 0))
        {
          this.showNavbar = true;
          if(this.onLandingPage == true){
            document.getElementById("NavBarContainer").style.background = "#ffffff";
            var i;
            navLogo.src = require("@/assets/gachita.png");
            nav_toast_btn.src = require("@/assets/toast_black.svg");
            for (i=0; i<navText_arr.length; i++)
              navText_arr[i].id = "navText_contents"
          }
          else{
             document.getElementById("NavBarContainer").style.background = "#ffffff";
          }
        }
        else
        {
          this.showNavbar = false;
        }
        if(currentScrollPosition == 0)
        {
          document.getElementById("NavBarContainer").style.background = "transparent";
          if(this.onLandingPage == true){
            var i;
            navLogo.src = require("@/assets/gachita.png");
            nav_toast_btn.src = require("@/assets/toast_white.svg");
            for (i=0; i<navText_arr.length; i++)
              navText_arr[i].id = "navText_main"
          }
        }
        // Set the current scroll position as the last scroll position
        this.lastScrollPosition = currentScrollPosition
        },

        mNavOpen() {
          var mNav = document.getElementById("mobileNav");
          mNav.style.display = "block";
        },
        mNavClose(){
          var mNav = document.getElementById("mobileNav");
          mNav.style.display = "none";
        }
    },
    watch:
    {
      $route(to, from){
        // var navText_arr = document.getElementsByClassName("navText");
        var navText_arr = document.getElementsByClassName("navText");
        var navLogo = document.getElementById("navLogo");
        var nav_toast_btn = document.getElementById("toast_btn");

        if(this.$router.currentRoute.path == "/"){
          this.onLandingPage = true;
          var i;
          navLogo.src = require("@/assets/gachita.png");
          nav_toast_btn.src = require("@/assets/toast_white.svg");
          for (i=0; i<navText_arr.length; i++)
            navText_arr[i].id = "navText_main"
        }
        else{
          this.onLandingPage = false;
          var i;
          navLogo.src = require("@/assets/gachita.png");
          nav_toast_btn.src = require("@/assets/toast_black.svg");
          for (i=0; i<navText_arr.length; i++)
            navText_arr[i].id = "navText_contents"
        }

      }
    }
}
</script>

<style scoped>

a:hover{

  text-decoration: none !important;
}
#NavBarContainer{
  z-index: 9999;

  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: 0px;
  margin-left: 0px;

  min-height: 48px;

  position: fixed;
  top: 0;

  width: 100%;
  min-width: 320px;


  font-family: 'NanumSquare';
  font-size: 15px;

  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #151819;
}



.navbar {

  transform: translate3d(0, 0, 0);
  transition: 0.3s all ease-out;
}
.navbar.navbar--hidden {
  transform: translate3d(0, -100%, 0);
  transition: 0.2s all ease-out;

}


#mobileNav{
  display:none;
  width: 100%;
  height: 200vh;
  min-width: 320px;
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: 999;
}
#mNavLogo{
  z-index: 1000;
  position: fixed;
  top: -1px;
  left: 50%;
  transform: translate(-50%,100%);
}
#mNavCancel{
  position: fixed;
  left: 24px;
  top: 22px;
}
#mNavTextContainer{
  position: fixed;

  left: 50%;
  transform: translate(-50%,33vh);

}
.mNavText{
  font-size: 22px;
  white-space: nowrap;
  position: relative;
  color: #000000;
  text-decoration-line: none;
}
.mNavSpacer{
  height: 8vh;
}
#toast{
  position: absolute;
  left: 24px;
  top: 22px;
}

.row{
  width: 100%;
}

.right-align{
  text-align: right;
}
.left-align{
  text-align: left;
}

.Logo {
  width: 120px;
  height: 21px;
  object-fit: contain;
}

#nopadding{

  padding: 0px;
  margin: 0px;
}
.LogoContainer {
  padding: 0px;
}

#navText_contents{
  white-space: nowrap;
  color: #000000;
}
#navText_main{
  white-space: nowrap;
  color: #ffffff;
}

#navText_contents:hover{
  text-decoration-line: none;
  white-space: nowrap;
  /* color: #000000; */
}

#navText_main:hover{
  text-decoration-line: none;
  white-space: nowrap;
  /* color: #ffffff; */
}

.router-link-exact-active {
  font-weight: bold;

  color: #1d55e8 !important;
}
</style>
